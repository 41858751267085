import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import PageTransition from "../lib/PageTransition";
import * as Styles from "./styles";
import api from "../api/api";
import $ from "jquery";
import Service from "../components/Service";
class App extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      preset: "scaleDownFromRight",
      pages: [
        {
          id: 144,
          title: "Construcción",
          slider_izquierdo: "<div></div>",
          slider_id: 123,
          titulo_del_banner: "Construcción",
          icon_home_page:
            "https://ambienteydesarrollo.cr/wp-content/uploads/2019/11/construccion.png",
          ancho_del_titulo: "350",
          icono_lista: ["ic-construction"],
          imagen_del_banner:
            "https://ambienteydesarrollo.cr/wp-content/uploads/2019/11/bg_building.jpg",
          content: "<ul></ul>",
          slug: "construccion",
          path: "/servicio/construccion/",
        },
        {
          id: 143,
          title: "Elaboración de Productos Alimenticios",
          slider_izquierdo: "<div id='empty'></div>",
          slider_id: 0,
          titulo_del_banner: "Elaboración de Productos Alimenticios",
          icon_home_page:
            "https://ambienteydesarrollo.cr/wp-content/uploads/2019/11/elaboracion_de_productos_alimenticios-1.png",
          ancho_del_titulo: "450",
          icono_lista: ["icon_elaboracion_de_productos_alimenticios"],
          imagen_del_banner:
            "https://ambienteydesarrollo.cr/wp-content/uploads/2019/11/bg_farmers.jpg",
          content: "<ul></ul>",
          slug: "elaboracion-de-productos-alimenticios",
          path: "/servicio/elaboracion-de-productos-alimenticios/",
        },
        {
          id: 142,
          title: "Explotación Minera",
          slider_izquierdo: "<div id='empty'></div>",
          slider_id: 0,
          titulo_del_banner: "Explotación Minera",
          icon_home_page:
            "https://ambienteydesarrollo.cr/wp-content/uploads/2019/11/explotacion_minera.png",
          ancho_del_titulo: "400",
          icono_lista: ["icon-mining"],
          imagen_del_banner:
            "https://ambienteydesarrollo.cr/wp-content/uploads/2019/11/bg_mining.jpg",
          content: "<ul></ul>",
          slug: "explotacion-minera",
          path: "/servicio/explotacion-minera/",
        },
        {
          id: 140,
          title: "Proyectos Agropecuarios",
          slider_izquierdo: "<div id='empty'></div>",
          slider_id: 0,
          titulo_del_banner: "Proyectos Agropecuarios",
          icon_home_page:
            "https://ambienteydesarrollo.cr/wp-content/uploads/2019/11/proyectos_agropecuarios.png",
          ancho_del_titulo: "400",
          icono_lista: ["icon-farming"],
          imagen_del_banner:
            "https://ambienteydesarrollo.cr/wp-content/uploads/2019/11/bg_farmers.jpg",
          content: "<ul></ul>",
          slug: "proyectos-agropecuarios",
          path: "/servicio/proyectos-agropecuarios/",
        },
        {
          id: 139,
          title: "Servicios Generales",
          slider_izquierdo: "<div id='empty'></div>",
          slider_id: 0,
          titulo_del_banner: "Servicios Generales",
          icon_home_page:
            "https://ambienteydesarrollo.cr/wp-content/uploads/2019/11/servicios_generales.png",
          ancho_del_titulo: "400",
          icono_lista: ["icon-servicios-generales"],
          imagen_del_banner:
            "https://ambienteydesarrollo.cr/wp-content/uploads/2019/11/tramites.jpg",
          content: "<ul></ul>",
          slug: "servicios-generales",
          path: "/servicio/servicios-generales/",
        },
        {
          id: 138,
          title: "Dirección General y Comercialización de Combustibles",
          slider_izquierdo: "<div id='empty'></div>",
          slider_id: 0,
          titulo_del_banner:
            "Dirección General y Comercialización de Combustibles",
          icon_home_page:
            "https://ambienteydesarrollo.cr/wp-content/uploads/2019/11/direccion_general_y_comercializacion_de_combustibles.png",
          ancho_del_titulo: "450",
          icono_lista: ["icon-gas_station"],
          imagen_del_banner:
            "https://ambienteydesarrollo.cr/wp-content/uploads/2019/11/bg_gas_station.jpg",
          content: "<ul></ul>",
          slug: "direccion-general-y-comercializacion-de-combustibles",
          path: "/servicio/direccion-general-y-comercializacion-de-combustibles/",
        },
        {
          id: 137,
          title: "Departamento de Aguas del MINAET",
          slider_izquierdo: "<div id='empty'></div>",
          slider_id: 0,
          titulo_del_banner: "Departamento de Aguas del MINAET",
          icon_home_page:
            "https://ambienteydesarrollo.cr/wp-content/uploads/2019/10/departamento_de_aguas_del_minaet.png",
          ancho_del_titulo: "550",
          icono_lista: ["icon-departamento-de-agua"],
          imagen_del_banner:
            "https://ambienteydesarrollo.cr/wp-content/uploads/2019/11/bg_river.jpg",
          content: "<ul></ul>",
          slug: "departamento-de-aguas-del-minaet",
          path: "/servicio/departamento-de-aguas-del-minaet/",
        },
        {
          id: 121,
          title: "Dirección de Geología y Minas del MINAET",
          slider_izquierdo: "<div id='empty'></div>",
          slider_id: 0,
          titulo_del_banner: "Dirección de Geología y Minas del MINAET",
          icon_home_page:
            "https://ambienteydesarrollo.cr/wp-content/uploads/2019/11/direccion_de_geologia_y_minas_del_minaet.png",
          ancho_del_titulo: "",
          icono_lista: ["icon-direccion-de-geologia"],
          imagen_del_banner:
            "https://ambienteydesarrollo.cr/wp-content/uploads/2019/11/bg_geology.jpg",
          content: "<ul></ul>",
          slug: "direccion-de-geologia-y-minas-del-minaet",
          path: "/servicio/direccion-de-geologia-y-minas-del-minaet/",
        },
        {
          id: 104,
          title: "Trámites en SETENA del MINAET",
          slider_izquierdo: "<div id='empty'></div>",
          slider_id: 0,
          titulo_del_banner: "Trámites en SETENA del MINAET",
          icon_home_page:
            "https://ambienteydesarrollo.cr/wp-content/uploads/2019/11/tramites_en_setena.png",
          ancho_del_titulo: "450",
          icono_lista: ["icon-tramite"],
          imagen_del_banner:
            "https://ambienteydesarrollo.cr/wp-content/uploads/2019/11/tramites.jpg",
          content: "<ul></ul>",
          slug: "tramites-en-setena-del-minaet",
          path: "/servicio/tramites-en-setena-del-minaet/",
        },
      ],
    };
  }
  componentDidMount = async () => {
    await api.get("/wp-json/api/servicios").then((response) => {
      this.setState({
        pages: response.data,
      });
    });
  };
  goToServices = () => {
    $("html, body").animate(
      {
        scrollTop: $("#servicios").offset().top,
        scrollLeft: 0,
      },
      2000
    );
  };
  render() {
    return (
      <Router>
        <Route
          render={({ location }) => (
            <React.Fragment>
              <Styles.Global />
              <PageTransition
                preset={this.state.preset}
                transitionKey={location.pathname}
              >
                <Switch location={location}>
                  {this.state.pages.map((page, index) => {
                    return (
                      <Route
                        key={index}
                        exact
                        path={page.path}
                        render={() => (
                          <Styles.Page color={"#ffffff"}>
                            <Service
                              goToServices={() => this.goToServices()}
                              page={page}
                            />
                          </Styles.Page>
                        )}
                      />
                    );
                  })}
                  <Route
                    exact
                    path="/"
                    render={() => (
                      <React.Fragment>
                        <h1>Servicios</h1>
                        <ul>
                          {this.state.pages.map((page, index) => (
                            <li key={index}>
                              <Link
                                title={page.title}
                                to={page.path}
                                onClick={() => this.goToServices()}
                              >
                                {page.icon_home_page !== "" ? (
                                  <img
                                    src={page.icon_home_page}
                                    alt={page.title}
                                    width="285px"
                                    height="230px"
                                  />
                                ) : (
                                  page.title
                                )}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </React.Fragment>
                    )}
                  />
                  <Route
                    exact
                    path="/home-page/"
                    render={() => (
                      <React.Fragment>
                        <h1>Servicios</h1>
                        <ul>
                          {this.state.pages.map((page, index) => (
                            <li key={index}>
                              <Link
                                to={page.path}
                                title={page.title}
                                onClick={() => this.goToServices()}
                              >
                                {page.icon_home_page !== "" ? (
                                  <img
                                    src={page.icon_home_page}
                                    alt={page.title}
                                    width="285px"
                                    height="230px"
                                  />
                                ) : (
                                  page.title
                                )}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </React.Fragment>
                    )}
                  />
                </Switch>
              </PageTransition>
            </React.Fragment>
          )}
        />
      </Router>
    );
  }
}
export default App;
