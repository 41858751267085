import React from "react";
import parse from "html-react-parser";
import { Link } from "react-router-dom";
import $ from "jquery";

class Service extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      slideIndex: 1,
    };
  }
  plusSlides = (n) => {
    const tempIndex = this.state.slideIndex + n;
    this.setState({
      slideIndex: tempIndex,
    });
    this.showSlides(tempIndex);
  };

  showSlides = (n) => {
    var i;
    var slides = document.querySelectorAll("#services-root .swiper-slide");

    if (n > slides.length) {
      this.setState({
        slideIndex: 1,
      });
    }
    if (n < 1) {
      this.setState({
        slideIndex: slides.length,
      });
    }
    for (i = 0; i < slides.length; i++) {
      slides[i].style.display = "none";
    }
    slides[this.state.slideIndex - 1].style.display = "block";
  };
  componentDidMount = () => {
    var $iconoLista = this.props.page.icono_lista;
    if ($iconoLista[0] === "ic-construction") {
      $(".article-content ul li").prepend(
        "<span class='icomoon icon-construction' title='Construcción'></span>"
      );
    }
    if ($iconoLista[0] === "icon_elaboracion_de_productos_alimenticios") {
      $(".article-content ul li").prepend(
        "<img title='Elaboración de Productos Alimenticios' alt='Elaboración de Productos Alimenticios' src='https://ambienteydesarrollo.cr/wp-content/themes/ambiente_y_desarrollo/assets/img/experiencia/icons/icon_elaboracion_de_productos_alimenticios.svg'/>"
      );
    }
    if ($iconoLista[0] === "icon-mining") {
      $(".article-content ul li").prepend(
        "<span class='icomoon icon-mining'></span>"
      );
    }
    if ($iconoLista[0] === "icon-farming") {
      $(".article-content ul li").prepend(
        "<span class='icomoon icon-farming'></span>"
      );
    }
    if ($iconoLista[0] === "icon-servicios-generales") {
      $(".article-content ul li").prepend(
        "<img title='Servicios Generales' alt='Servicios Generales' src='https://ambienteydesarrollo.cr/wp-content/themes/ambiente_y_desarrollo/assets/img/servicios/icons/fonts/servicios_generales.svg'/>"
      );
    }
    if ($iconoLista[0] === "icon-gas_station") {
      $(".article-content ul li").prepend(
        "<span class='icomoon icon-gas_station'></span>"
      );
    }
    if ($iconoLista[0] === "icon-departamento-de-agua") {
      $(".article-content ul li").prepend(
        "<img title='Departamento de Agua' alt='Departamento de Agua' src='https://ambienteydesarrollo.cr/wp-content/themes/ambiente_y_desarrollo/assets/img/servicios/icons/fonts/departamento_de_aguas_del_minaet.svg'/>"
      );
    }
    if ($iconoLista[0] === "icon-direccion-de-geologia") {
      $(".article-content ul li").prepend(
        "<img title='Dirección de Geología' alt='Dirección de Geología' src='https://ambienteydesarrollo.cr/wp-content/themes/ambiente_y_desarrollo/assets/img/servicios/icons/fonts/direccion_de_geologia_y_minas_del_minaet.svg'/>"
      );
    }
    if ($iconoLista[0] === "icon-tramite") {
      $(".article-content ul li").prepend(
        "<img title='Trámites' alt='Trámites' src='https://ambienteydesarrollo.cr/wp-content/themes/ambiente_y_desarrollo/assets/img/servicios/icons/fonts/tramites_en_setena.svg'/>"
      );
    }
    try {
      document
        .querySelector("#services-root .swiper-button-prev")
        .addEventListener("click", this.getPrevSlider);
      document
        .querySelector("#services-root .swiper-button-next")
        .addEventListener("click", this.getNextSlider);
      this.showSlides(1);
    } catch (error) {
      console.error("An error occurs in Service.js ", error);
    }
  };
  getPrevSlider = () => {
    this.plusSlides(-1);
  };
  getNextSlider = () => {
    this.plusSlides(1);
  };
  render() {
    return (
      <React.Fragment>
        <Link
          to={"/"}
          onClick={() => this.props.goToServices()}
          title="Go Back"
          className="btn hvr-rectangle-out"
        >
          {"<<"}Volver
        </Link>
        <div style={{ margin: "auto", maxWidth: "none", width: "100%" }}>
          <div
            className="banner"
            id="banner"
            style={{
              background:
                "url(" +
                this.props.page.imagen_del_banner +
                ") no-repeat center center",
              backgroundAttachment: "fixed",
            }}
          >
            {this.props.page.ancho_del_titulo !== "" ? (
              <h1 style={{ maxWidth: this.props.page.ancho_del_titulo + "px" }}>
                {this.props.page.title}
              </h1>
            ) : (
              <h1>{this.props.page.title}</h1>
            )}
          </div>
          <article className="article-content">
            <div className="col-lg-12">
              <div className="col-lg-6 col-md-6">
                {this.props.page.content !== ""
                  ? parse(this.props.page.content)
                  : ""}
              </div>
              <div className="col-lg-6 col-md-6 slider-izquierdo">
                {parse(this.props.page.slider_izquierdo)}
              </div>
            </div>
          </article>
        </div>
      </React.Fragment>
    );
  }
}
export default Service;
