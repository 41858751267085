import styled, {  createGlobalStyle } from 'styled-components';

export const Global = createGlobalStyle`
    html, body, #services-root {
        height: 100%;
    } 
    body{
      background:#ffffff;
    }
    .container #services-root .article-content ul li{
      width:100%;
      float:left;
      max-width: none;
      text-align: start;
    }
`; 
export const Page = styled.div`
  background-color: ${({ color }) => color};
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding:0;
  width: 100%;
`; 